@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-black-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-bold-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-medium-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-black-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-bold-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-medium-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-regular-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/mavenpro-light-webfont.woff2') format('woff2'),
    url('./fonts/maven/mavenpro-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/maven/maven-pro-light-200.woff2') format('woff2'),
    url('./fonts/maven/maven-pro-light-200.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Maven Pro';
  min-height: 100%;
}

html,
body,
#root {
  height: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.14) !important;
}
